'use client';

import { useFormStatus } from 'react-dom';

import styles from './SubmitButton.module.css';

const SubmitButton = () => {
  const { pending } = useFormStatus();

  return (
    <button
      form="contact-form"
      className={styles.button}
      type="submit"
      aria-disabled={pending}
      disabled={pending}
    >
      שלח הודעה
    </button>
  );
};

export default SubmitButton;
