import type { FC } from 'react';

import styles from './filterLayout.module.css';
import type { FilterLayoutProps } from './FilterLayout.types';

const FilterLayout: FC<FilterLayoutProps> = ({ title, children }) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      {children}
    </div>
  );
};
export default FilterLayout;
