'use client';

import Image from 'next/image';
import Link from 'next/link';

import linkBox from '@/public/assets/icons/contactUs/linkBox.svg';

import styles from './Navbar.module.css';
import type { INavbarProps } from './Navbar.types';

const Navbar: React.FC<INavbarProps> = ({ handleOnClick }) => {
  const links = [
    { name: 'ראשי', link: '/' },
    { name: 'בלוגים', link: 'https://www.anashimil.org/posts', isExternal: true },
    { name: 'אנשים', link: 'https://www.anashimil.org/', isExternal: true },
    { name: 'צור קשר', link: '/#contact-form' },
  ];

  return (
    <nav className={styles.navbar}>
      <ul className={styles.list}>
        {links.map(({ name, link, isExternal }) => (
          <li key={name}>
            {isExternal ? (
              <div className={styles.linkContainerWithIcon}>
                <Link
                  onClick={handleOnClick}
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link}
                >
                  {name}
                </Link>
                <Image src={linkBox} alt="link" />
              </div>
            ) : (
              <Link onClick={handleOnClick} className={styles.link} href={link}>
                {name}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
